footer {
  width: 100%;
  color: #bbb;
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;
}

@media (max-width: 800px) {

  footer {
    font-size: 14px;
  }

}