.rs-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  opacity: 0;
  line-height: 1.22222267;
}
.rs-tooltip.fade {
  -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}
.rs-tooltip.in {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.rs-tooltip .rs-tooltip-inner {
  max-width: 250px;
  padding: 2px 10px;
  color: #bbbbbb;
  text-align: center;
  background-color: #222222;
  border-radius: 4px;
  overflow-wrap: break-word;
}
.rs-tooltip .rs-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rs-tooltip[class*='placement-top'] {
  margin-top: -8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-top'].in {
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
}
.rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #222222;
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -5px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -5px;
  }
}
.rs-tooltip[class*='placement-bottom'] {
  margin-top: 8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-bottom'].in {
  -webkit-transform: translate(0, 2px);
          transform: translate(0, 2px);
}
.rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #222222;
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -5px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -5px;
  }
}
/* rtl:begin:ignore */
.rs-tooltip[class*='placement-right'] {
  margin-left: 8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-right'].in {
  -webkit-transform: translate(2px, 0);
          transform: translate(2px, 0);
}
.rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #222222;
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -5px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -5px;
  }
}
.rs-tooltip[class*='placement-left'] {
  margin-left: -8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-left'].in {
  -webkit-transform: translate(-2px, 0);
          transform: translate(-2px, 0);
}
.rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #222222;
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -5px;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -5px;
  }
}
/* rtl:end:ignore */
.rs-tooltip.placement-bottom .rs-tooltip-arrow,
.rs-tooltip.placement-top .rs-tooltip-arrow {
  left: 50%;
}
.rs-tooltip.placement-bottom-start .rs-tooltip-arrow,
.rs-tooltip.placement-top-start .rs-tooltip-arrow {
  left: 10px;
}
.rs-tooltip.placement-bottom-end .rs-tooltip-arrow,
.rs-tooltip.placement-top-end .rs-tooltip-arrow {
  right: 4px;
}
.rs-tooltip.placement-right .rs-tooltip-arrow,
.rs-tooltip.placement-left .rs-tooltip-arrow {
  top: 50%;
}
.rs-tooltip.placement-right-start .rs-tooltip-arrow,
.rs-tooltip.placement-left-start .rs-tooltip-arrow {
  top: 10px;
}
.rs-tooltip.placement-right-end .rs-tooltip-arrow,
.rs-tooltip.placement-left-end .rs-tooltip-arrow {
  bottom: 4px;
}